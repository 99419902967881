import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageTemplateProps {
    data: {
        page: {
            path?: string
            title?: string
            seo: any
            flexContent: any
        }
    }
    location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
    data: {
        page: { title, flexContent, seo },
    },
    location = {},
}) => (
    <Layout>
        <SEO seo={seo} />

        <FlexContentHandler
            title={title}
            prefix="Accessoire_Flexcontent"
            fields={flexContent}
            location={location}
        />
    </Layout>
)

export const pageQuery = graphql`
  query AccessoireById($pageId: String!) {
    page: wpAccessoire(id: { eq: $pageId }) {
      ...generalAccessoireFragment
    }
  }
`

export default PageTemplate
